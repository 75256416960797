// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
// Components
import SEO from '../components/seo'
import Layout from '../components/layout'
import Markdown from '../components/markdown'
import Picture from '../components/picture'

class IndexPage extends React.Component {

  render() {

    const homepage = this.props.data.allContentfulHomepage.edges[0].node

    return (
      <Layout
        {...homepage}
        masthead={homepage.tagline}
        isHome={true}
        fgColor="#36A8F5"
        hasFooter
      >
        <SEO title="Make–Ready" />
        <div className="innerx8">

          <FeaturedProjects projects={homepage.featuredProjects} test={i => i <= 3} />

          <section className="innerx4 container">
            <h1 className="client-wall">
              <div>Our clients include—</div>
              {homepage.clientWall.map((tag, i) => (
                <span className="client-tag" key={tag.slug} >{tag.name}</span>
              ))}
            </h1>
          </section>

          <FeaturedProjects projects={homepage.featuredProjects} test={i => i > 3} />

        </div>
      </Layout>
    )
  }
}

const FeaturedProjects = ({projects, test}) => (
  <div>
    {projects.map((node, i) => {
      return test(i) ? (
        <section className="innerx4 container" key={`project-${i}`}>
          <Link to={`/portfolio/${node.slug}`}>
            <Picture className={`${node.coverSize ? 'pro-height' : ''}`} {...node.cover} {...node.cover.fluid} test={i === 0} />
            <figcaption>
              <span>
                <Markdown html={node.coverCopy.childMarkdownRemark.html} />
              </span>
            </figcaption>
          </Link>
        </section>
      ) : null
    })}
  </div>
)

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default IndexPage

export const pageQuery = graphql`
  query pageQuery {
    allContentfulHomepage (
      limit: 1
    ) {
      edges {
        node {
          pageTitle
          tagline
          contactDetails {
            childMarkdownRemark {
              html
            }
          }
          mapLink
          email
          telephone
          featuredProjects {
            title
            slug
            coverSize
            coverCopy {
              childMarkdownRemark {
                html
              }
            }
            cover {
              fluid(maxWidth: 1200, quality: 90) {
                sizes
                src
                srcSet
                aspectRatio
              }
            }
          }
          clientWall {
            name
            slug
            type
          }
          projectFooterCopy {
            childMarkdownRemark {
              html
            }
          }
          seoTitle
          seoDescription
          seoImage {
            fluid(maxHeight: 500, quality: 80) {
              src
            }
          }
        }
      }
    }
  }
`
